<template>
	<div class="order-list">
		
		<div class="latest">
			<div class="one">
				<div>
					<svg style="width: 4rem;color:#1690ff" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
					<p style="font-weight: 900">最新版本为: {{ version[0] && version[0].version }}</p>
					<p style="font-weight: 400">当前版本为: {{ nowVersionStr }}</p>
				</div>
			</div>
			
			<div>
				<div>
					<svg style="width: 4rem;color:#1690ff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
					<p style="font-weight: 900">更新内容</p>
					<p style="font-weight: 400">{{version[0] && version[0].desc}}</p>
				</div>
			</div>
			
			<div>
				<div>
					<svg style="width: 4rem;color:#1690ff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
					<p style="font-weight: 900">更新时间</p>
					<p style="font-weight: 400">{{version[0] && version[0].time}}</p>
				</div>
			</div>
		</div>
		
		<div class="update-bottom">
			<div>
				<div>
					<el-button type="primary" @click="download" size="medium">点击下载</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	data() {
		return {
			nowVersion: 1,
			nowVersionStr: 'v1.0.0',
			version: []
		};
	},
	
	watch: {},
	
	mounted() {
		this.getVersion()
		this.nowVersionStr = process.env.VUE_APP_VERSION
		this.nowVersion = process.env.VUE_APP_VERSION_INT
	},
	
	methods: {
		getVersion() {
			return this.$http.post('/admin/common/version', {}).then(res => {
				this.version = res.data
			})
		},
		download() {
			this.$http.post('/admin/common/getClientDownloadUrl', {}).then(res => {
				window.open(process.env.VUE_APP_BASE_API + res['data']['url'])
			}).catch(err => {
				console.log(err)
			})
		}
	},
};
</script>
<style lang="scss" scoped>
.bind-item {
	margin: 2rem 1rem;
	padding:1rem;
	border: solid 1px #eee;
	border-radius:1rem;
	.version {
		font-size: 2rem;
		font-weight: 600;
		color: #0d5aa7;
	}
	h1 {
		margin: 1rem;
		text-align: center;
	}
	.bind-sure {
		display: flex;
		justify-content: center;
	}
	::v-deep .el-textarea__inner {
		height: 5rem !important;
	}
	.desc {
		text-align: left;
	}
	.title {
		text-align: left;
	}
	.time{
		text-align: left;
	}
}
::v-deep .el-form-item__label {
	padding-right: 15px !important;
}
.logoUpload {
	display: flex;
	justify-content: center;
}
.logoImg {
	display: flex;
	justify-content: center;
	margin:1rem auto 2rem auto;
	border: solid 1px #eee;
	border-radius: 50%;
	max-width: 100px;
	text-align: center;
	img {
		max-width: 100%;
	}
}
.logoImgBanner {
	max-width: 100px;
}
.banner-upload {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin:1rem auto 2rem auto;
	border-radius: 50%;
	max-width: 100px;
	text-align: center;
	button {
		height: 2rem;
	}
	img {
		max-width: 100%;
	}
	.banner-button {
		display: flex;
		justify-content: center;
		align-content: center;
	}
	h3 {
		margin-bottom: 2rem;
	}
}
.latest {
	margin-top: 2rem;
	display: flex;
	width: 100%;
	justify-content: space-around;
	>div{
		width: 33%;
		text-align: center;
	}
}
.update-bottom {
	margin-top: 2rem;
	text-align: center;
}
</style>
